import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { colors } from "../styles/variables";
import { kebabCase } from "lodash";
import Facebook from "../assets/facebook.svg";
import Instagram from "../assets/instagram.svg";
import Twitter from "../assets/twitter.svg";
import {InstagramFeed} from "./Instagram";

const FooterNav = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  a {
    color: ${colors.gold} !important;
    transition: 0.4s;
    &:hover,
    &.active {
      color: white !important;
    }
  }
  & > * {
    padding: 10px;
  }
`;

const IconContainer = styled.div`
  text-align: center;
  display: block;
  margin-bottom: 10px;
  .icon {
    margin: 0 9px;
    svg {
      width: 30px;
      fill: ${colors.offWhite};
      transition: fill 0.4s;
    }
    &:hover svg {
      fill: ${colors.black};
    }
  }
`;

const Footer = () => {
   return (
        <>
          <InstagramFeed />
        <footer className="has-background-black">
          <div style={{ backgroundColor: colors.black }}>
            <FooterNav className="container">
              <Link
                to={`page/${kebabCase("looking-after-your-jewellery")}`}
                activeClassName="active"
              >
                Looking after your jewellery
              </Link>
              <Link
                to={`page/${kebabCase("t-c-privacy-policy")}`}
                activeClassName="active"
              >
                T&C / Privacy policy
              </Link>
              <Link
                to={`page/${kebabCase("customer-services")}`}
                activeClassName="active"
              >
                Customer services
              </Link>
              <Link to="/contact" activeClassName="active">
                Contact
              </Link>
            </FooterNav>
          </div>
          <div
            className="section"
            style={{ backgroundColor: colors.accent, color: "#ffe" }}
          >
            <div className="container">
              <IconContainer>
                <a
                  href="https://www.facebook.com/NaomiDaviesJewellery/"
                  className="icon"
                >
                  <Facebook />
                </a>
                <a href="https://twitter.com/naomidjewellery" className="icon">
                  <Twitter />
                </a>
                <a
                  href="https://www.instagram.com/naomidaviesjewellery"
                  className="icon"
                >
                  <Instagram />
                </a>
              </IconContainer>
            </div>
            <div className="container has-text-centered">
              <p>
                Copyright © 2020 Naomi Davies. (All rights reserved.) - Designed &
                Developed by{" "}
                <a
                  href="https://www.louisemoxhay.co.uk/"
                  style={{ color: "inherit", textDecoration: "underline" }}
                >
                  Louise Moxhay
                </a>
                - Photography by Lesley-Jo Thompon, Sophie Baxter, Naomi
                Davies-Tonkin
              </p>
            </div>
          </div>
        </footer>
        </>
    );
};

export default Footer;
