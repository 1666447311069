import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import { Header } from "../components/header";
import "bulma/css/bulma.min.css";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import styled from "styled-components";
import { colors } from "../styles/variables";

const Container = styled.div`
  font-family: "Roboto", sans-serif;
  color: ${colors.black};
  min-height: 100vh;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 500px) {
    font-size: 12px;
  }
  a {
    color: ${colors.grey};
    &:hover {
      color: ${colors.black};
    }
  }
  *:focus {
    outline: none;
  }
`;

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <Container>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
          rel="stylesheet"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="jewellery" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
      </Helmet>
      <Header />
        <div>{children}</div>
      <Footer />
    </Container>
  );
};

export default TemplateWrapper;
