import React from "react";
import { Link } from "gatsby";
import { ShoppingIcon } from "../icons/shoppingBag";
import styled from "styled-components";
import { colors } from "../styles/variables";
const config = require("../config/site-settings.json");

const Container = styled.nav`
  background: ${colors.black};
  .navbar-brand img {
    padding: 10px;
  }
  .navbar-end {
    flex: 1;
    justify-content: space-between;
    margin-left: 100px;
  }
  a.navbar-item:hover {
    background: transparent;
  }
  a.navbar-item.active span {
    border-color: ${colors.gold};
    &:after,
    &:before {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @media (min-width: 1023px) {
    .snipcart-checkout > span {
      display: none;
    }
    a.navbar-item.active span {
      border-color: ${colors.gold};
      &:after,
      &:before {
        transform: translateX(0);
        opacity: 1;
      }
    }
    a.navbar-item span {
      &:before,
      &:after {
        position: absolute;
        content: "►";
        right: -15px;
        top: 4px;
        font-size: 10px;
        transform: translateX(-15px);
        transition: 0.35s;
        opacity: 0;
      }
      &:after {
        content: "◀";
        left: -15px;
        font-size: 10px;
        transform: translateX(15px);
      }
      &:hover {
        border-color: ${colors.gold};
        &:after,
        &:before {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }
  a.navbar-item span, .snipcart-checkout > span {
    text-transform: uppercase;
    color: ${colors.gold};
    position: relative;
    border-bottom: 1px solid;
    border-color: transparent;
  }
  .navbar-menu {
    display: flex;
    background: ${colors.black};
  }
  .navbar-burger {
    color: ${colors.gold};
    align-self: center;
    transition: 0.4s;
    span {
      transition: 0.4s;
    }
    &:hover {
      color: white;
    }
  }
  @media (max-width: 1023px) {
    .snipcart-checkout svg {
      display: none;
      color: ;
    }
    &.navOpen {
      position: sticky;
      top: 0;
      width: 100%;
      .navbar-menu {
        transform: translateX(0);
      }
    }
    .navbar-menu {
      position: fixed;
      right: 0;
      padding: 20px;
      height: 100vh;
      transform: translateX(100%);
      transition: 0.4s;
    }
    .navbar-end {
      margin-left: 0;
    }
  }
`;

const Icon = styled.div`
  cursor: pointer;
  svg {
    transition: fill 0.8s;
    fill: ${colors.gold};
    margin-top: 10px;
  }
  &:hover svg {
    fill: white;
  }
`;

export const Header = () => {
  const [itemCount, setItemCount] = React.useState(0);
  const [navOpen, setNavOpen] = React.useState(false);
  React.useEffect(() => {
    document.addEventListener("snipcart.ready", function() {
      setItemCount(window.Snipcart.api.items.count());
    });
  });
  return (
    <Container
      className={`navbar ${navOpen ? "navOpen" : ""}`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link to="/">
          <img src={config.logo} alt={config.name} width="260" height="80" />
        </Link>

        <a
          role="button"
          className={`navbar-burger ${navOpen ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setNavOpen(!navOpen)}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div id="navbar" className="navbar-menu">
        <div className="navbar-start" />
        <div className="navbar-end">
          <Link className="navbar-item" to="/" activeClassName="active">
            <span>Home</span>
          </Link>
          <Link className="navbar-item" to="/about" activeClassName="active">
            <span>About</span>
          </Link>
          <Link
            className="navbar-item"
            to="/shop"
            activeClassName="active"
            partiallyActive={true}
          >
            <span>Shop</span>
          </Link>
          <Link
              className="navbar-item"
              to="/bespoke-orders"
              activeClassName="active"
              partiallyActive={true}
          >
            <span>Bespoke orders</span>
          </Link>
          <Link
            className="navbar-item"
            to="/page/courses"
            activeClassName="active"
            partiallyActive={true}
          >
            <span>Courses</span>
          </Link>
          <Link className="navbar-item" to="/contact" activeClassName="active">
            <span>Contact</span>
          </Link>
          <Icon className="navbar-item">
            <div className="snipcart-checkout">
              <span>Account</span>
              <ShoppingIcon height={35} width={35} count={itemCount} />
            </div>
          </Icon>
        </div>
      </div>
    </Container>
  );
};
