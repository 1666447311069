import React from 'react'

export const ShoppingIcon = ({height, width, count}) => (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 125 125" x="0px" y="0px" width={width || '25'} height={height || '25'}>
        <path d="M81,91H19a3,3,0,0,1-3-3.21l4-56A3,3,0,0,1,23,29H77a3,3,0,0,1,3,2.79l4,56A3,3,0,0,1,81,91ZM22.22,85H77.78L74.21,35H25.79Z"/>
        <path d="M64,26a3,3,0,0,1-3-3,11,11,0,0,0-22,0,3,3,0,0,1-6,0,17,17,0,0,1,34,0A3,3,0,0,1,64,26Z"/>
        <path d="M36,50a3,3,0,0,1-3-3V23a3,3,0,0,1,6,0V47A3,3,0,0,1,36,50Z"/>
        <path d="M64,50a3,3,0,0,1-3-3V23a3,3,0,0,1,6,0V47A3,3,0,0,1,64,50Z"/>
        <text x="50" y="78" fontSize="35" textAnchor="middle">{count}</text>
    </svg>
);