import React, {useEffect, useState} from "react";

export const InstagramFeed = () => {
    const [photos, setPhotos] = useState([])
    useEffect(() => {
        const getImgs = async () => {
            try {
                // // Hack from https://stackoverflow.com/a/47243409/2217533
                const response = await fetch(
                    `/instagram/graphql/query?query_id=17888483320059182&variables={"id":"1645799400","first":6,"after":null}`,
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*'
                        }
                    }
                )
                const { data } = await response.json()
                const photos = data
                    ? data.user.edge_owner_to_timeline_media.edges.map(
                        ({ node }) => {
                            const { id } = node
                            const src = node.thumbnail_src
                            const url = `https://www.instagram.com/p/${node.shortcode}`
                            return {
                                id,
                                src,
                                url
                            }
                        }
                    )
                    : []
                setPhotos(photos)
            } catch (error) {
                console.error(error)
            }
        }
        getImgs()
    }, [])

    return (
        <div className="columns is-gapless is-mobile" style={{ marginBottom: 0 }}>
            {photos.map(({url, id, src}) => (
                <a className="column" key={id} href={url}>
                    <figure className="image is-square">
                        <img src={src} alt={'instagram img'}/>
                    </figure>
                </a>
            ))}
        </div>
    )
}
